import { template as template_6ed20fdd62c746f9a5dc46d29ac46ee1 } from "@ember/template-compiler";
const FKLabel = template_6ed20fdd62c746f9a5dc46d29ac46ee1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
