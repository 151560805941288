import { template as template_e18cab9792734a08946111c8c4714130 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e18cab9792734a08946111c8c4714130(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
