import { template as template_ac5e6605fc814792bd1bb026c5286a9a } from "@ember/template-compiler";
const SidebarSectionMessage = template_ac5e6605fc814792bd1bb026c5286a9a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
